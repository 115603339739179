import React from 'react';
import '../css/FooMenu.css';

const FooTerms = () => {
  return (
    <div  id="main">
      <div className="FooMenu-container">
          <div className="terms-container">              
            <h2 className='Fooh2CSS'>이용약관</h2>
            <p>이용약관 내용</p>
          </div>
      </div>
    </div>

  );
};

export default FooTerms;
